import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import anime from 'animejs';
//import IconLoader from './icons/loader';
import LogoLoader from './icons/loaderLogo';
import styled from 'styled-components';


const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;
const LogoWrapper = styled.div`
  width: max-content;
  max-width: 500px;
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)';
  opacity: ${props => (props.isMounted ? 1 : 0)};
  svg {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    fill:#FFF;
    user-select: none;
    #capgroup, #idal {
      opacity: 0;
    }
  }
`;

const Loader = ({ finishLoading }) => {
  function animate () {
    const loader = anime.timeline({
      complete: () => finishLoading(),
    });

    loader
      .add({
        targets: '#idal',
        delay: 500,
        duration: 2000,
        easing: 'linear',
        fill: '#00426a',
        opacity: 1,
      })
      .add({
        targets: '#capgroup',
        delay: 1000,
        duration: 1000,
        easing: 'linear',
        fill: '#8dc8e8',
        opacity: 1
      }, 0)
      .add({
        targets: '#frontBottom',
        points: '149.91 55.12 149.91 73.06 187.89 71.85 187.89 59.86 149.91 55.12',
        duration: 1500,
        // loop: true,
        easing: 'linear'
      }, 100)
      .add({
        targets: '#sideBottom',
        points: '139.28 61.82 149.91 55.12 149.91 73.06 139.28 70.51 139.28 61.82',
        duration: 1500,
        // loop: true,
        easing: 'linear'
      }, -50)
      .add({
        targets: '#frontMiddle',
        points: '171.62 33.98 149.91 27.92 149.91 45.15 171.62 49.17 171.62 33.98',
        duration: 1500,
        // loop: true,
        easing: 'linear'
      }, 20)
      .add({
        targets: '#sideMiddle',
        points: '139.28 44.65 149.91 27.92 149.91 45.15 139.28 53.34 139.28 44.65',
        duration: 1500,
        // loop: true,
        easing: 'linear'
      }, 0)
      .add({
        targets: '#frontTop',
        points: '187.89 17.95 149.91 0 149.91 17.23 187.89 30.56 187.89 17.95',
        duration: 1500,
        // loop: true,
        easing: 'linear'
      }, 20)
      .add({
        targets: '#sideTop',
        points: '139.28 26.45 149.91 0 149.91 17.23 139.28 35.13 139.28 26.45',
        duration: 1500,
        // loop: true,
        easing: 'linear'
      }, 0)
      .add({
        targets: '#logo',
        delay: 700,
        duration: 300,
        easing: 'easeInOutQuart',
        opacity: 0,
        scale: 0.1
      })
      .add({
        targets: '.loader',
        duration: 200,
        easing: 'easeInOutQuart',
        opacity: 0,
        zIndex: -1
      })
  };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10)
    animate()
    return () => clearTimeout(timeout)
  }, [])

  return (
    <LoaderContainer className="loader">
      <Helmet bodyAttributes={{ class: `hidden` }} />

      <LogoWrapper isMounted={isMounted}>
        <LogoLoader />
      </LogoWrapper>
    </LoaderContainer>
  );
};

Loader.propTypes = {
  finishLoading: PropTypes.func.isRequired,
};

export default Loader;
