import React from 'react'
import { graphql } from 'gatsby'
import LayoutLoader from '../components/layout-loader'

const HomeTest = ({ data }) => {

  const { page } = data.wpgraphql
  const { numberCards } = page.acf_home_fields
return (
  <LayoutLoader>
    <h1>{page.title}</h1>
    <h2>{page.acf_home_fields.heroHead}</h2>
    <p>{page.acf_home_fields.heroText}</p>
    <ul>
        {numberCards.map(cards => (
         <div className="App">
          <h3>{cards.head}</h3>
          <h4>{cards.subhead}</h4>
          <p>{cards.text}</p>
          </div>
        ))}
      </ul>
  </LayoutLoader>
)
}


export default HomeTest

export const query = graphql`
  query {
  wpgraphql {
    page(id: "cGFnZTo1") {
      title
      acf_home_fields {
        heroHead
        heroText
        numberCards {
          head
          subhead
          text
        }
      }
    }
  }
}
`
